import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=768c53a9&scoped=true&"
import script from "./landing.vue?vue&type=script&lang=ts&"
export * from "./landing.vue?vue&type=script&lang=ts&"
import style0 from "./landing.vue?vue&type=style&index=0&id=768c53a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768c53a9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginButton: require('/app/components/landing/LoginButton.vue').default,SkeletonHero: require('/app/components/landing/skeletons/SkeletonHero.vue').default,SkeletonBullets: require('/app/components/landing/skeletons/SkeletonBullets.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VContainer,VRow})
