export default {
  // Name
  name: 'Studium',

  // Description
  description:
    'Studium is the platform for educational tips and materials that will help you process your courses. Get involved and start contributing!',

  // Tagline
  tagline: 'Your course sharing platform.',

  // Keywords
  keywords: 'Studium, UGent, UGent Samenvattingen, Samenvattingen, Delen, Universiteit Gent, Cursus, Cursus materiaal',

  // Authors
  authors: 'VTK Gent vzw, Gentse Studentenraad',

  // Preview image
  image: `${process.env.BASE_URL}/icons/icon.png`,

  // Domain
  domain: 'studium.gent',
}
